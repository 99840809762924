import React from "react"
import Layout from '../components/layout'
import GetInTouch from '../components/get-in-touch'

const IndustriesWeServePage = () => {
    return (
        <Layout>
            <div className="trt-industries-we-serve">
                <div className="services-banner">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-7 order-1 order-lg-0">
                                <h1 className="d-none d-sm-block">Voor wie werken wij?</h1>
                                <p className="mb-3">IJzersterk Tekenwerk wil graag een toegankelijk tekenbureau zijn voor bedrijven.</p>
                                <p className="mb-3">Sommigen hiervan zijn constructiebedrijven die een aantal lassersin dienst hebben en de werkvoorbereiding (deels) uitbesteden. Misschien heeft het bedrijf wel een werkvoorbereider maar moet het proces geprofessionaliseerd worden. Misschien kan het proces uitgebreid worden of betreft het een bedrijf wat sterk groeit.</p>
                                <p className="mb-3">Verbeteren kan met betere paklijsten of stuklijsten, revisietekeningen, productietekeningen of lastekeningen. Wij maken duidelijke handleidingen en renders voor producten.</p>
                                <p>Uiteindelijk willen wij er voor de ondernemer en het bedrijfsbureau zijn. Bij IJzersterk Tekenwerk heb je direct contact met engineers en liggen er duidelijke afspraken omtrent uren en prijzen.</p>
                            </div>
                            <div className="col-md-5 text-center">
                                <h1 className="d-block d-sm-none">Voor wie werken wij?</h1>
                                <img className="w-75"
                                    src="../images/industries-we-serve-banner.png"
                                    alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <GetInTouch />
            </div >
        </Layout >
    )
}

export default IndustriesWeServePage;